import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { FieldCheckbox, FieldSelectWithSearch } from 'components/form';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { AccountSelectorField } from '../../cabinet/header/account-selector';

const BookingSurrogateAgency = observer(({ formik, readOnly }) => {
    const [agenciesList, setAgenciesList] = useState([]);

    useEffect(() => {
        API.get({
            url: apiMethods.SURROGATE_AGENCIES,
            success: (list) => setAgenciesList(list),
        });
    }, []);

    const accounts = useMemo(() => {
        if (formik.values.surrogateAgencyId) {
            const selected = agenciesList.find(({ id }) => id === formik.values.surrogateAgencyId);
            if (selected) {
                return selected.accounts;
            }
        }
        return [];
    }, [formik.values.surrogateAgencyId, agenciesList]);

    let options = [{ name: 'None', id: null }, ...agenciesList].map(({ name, id }) => ({
        value: id,
        text: name,
    }));

    if (!agenciesList.length) {
        return null;
    }

    if (!formik.values.isSurrogateSearch && readOnly) {
        return null;
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <FieldCheckbox
                    formik={formik}
                    label="Add Surrogate Agency Details"
                    id="isSurrogateSearch"
                    disabled={readOnly}
                />
            </div>
            {formik.values.isSurrogateSearch ? (
                <div className="row">
                    <FieldSelectWithSearch
                        formik={formik}
                        id="surrogateAgencyId"
                        placeholder="Select Agency"
                        options={options}
                        label="Surrogate Agency"
                        className="size-medium"
                        readOnly={readOnly}
                        disabled={readOnly}
                    />
                    <AccountSelectorField
                        formik={formik}
                        disabled={!formik.values.surrogateAgencyId || readOnly}
                        accounts={accounts}
                    />
                </div>
            ) : null}
        </>
    );
});

export default BookingSurrogateAgency;
