import { makeAutoObservable } from 'mobx';
import autosave from 'htcore/misc/autosave';
import { SEARCH_STATUSES } from 'enum';
import { applyFilters, atLeastOne, createFilters } from 'tasks/utils/accommodation-filtering';

const DAY_IN_SECONDS = 24 * 60 * 60 * 1000;

class AccommodationStore {
    search = {
        loading: false,
        request: null,
        numberOfNights: 0,
        id: null,
        createdAt: null,
        taskState: '',
        lastCheckedAt: null,
        result: [],
        page: 0,
        resultCount: 0,
        filters: null,
        isLoadingRooms: true,
        roomsTaskState: null,
        roomsResultCount: 0,
        roomsLastCheckedAt: null,
        roomsCreatedAt: null,
        districts: null,
        chains: null,
    };
    selected = {
        accommodation: null,
        roomContractSet: null,
        accommodationFullDetails: null,
        accommodationFinal: null,
        availabilityId: null,
        sorter: null,
        filters: null,
    };
    booking = {
        request: {},
        result: null,
    };

    constructor() {
        makeAutoObservable(this);
        autosave(this, '_accommodation_store_cache');
    }

    clearSearch() {
        this.search = {
            resultCount: 0,
            result: [],
            taskState: SEARCH_STATUSES.STARTED,
            page: 0,
            createdAt: Number(new Date()),
            lastCheckedAt: null,
            loading: false,
            id: null,
            filters: null,
        };
        this.selected = {
            roomContractSet: null,
            accommodation: null,
            accommodationFullDetails: null,
            accommodationFinal: null,
            availabilityId: null,
            filters: null,
            sorter: null,
        };
    }

    setNewSearchRequest(request) {
        this.clearSearch();
        this.search.request = request;
        this.search.numberOfNights = Math.round(
            Math.abs(new Date(request.checkOutDate) - new Date(request.checkInDate)) / DAY_IN_SECONDS
        );
    }

    setSearchId(searchId) {
        this.search.id = searchId;
        this.search.taskState = SEARCH_STATUSES.CREATED;
    }

    setSearchResult({ availabilities, availabilityCount }, page = 0) {
        this.search.result = availabilities;
        this.search.loading = false;
        this.search.lastCheckedAt = Number(new Date());
        this.search.page = page;
        this.search.availabilityCount = availabilityCount;

        this.search.filters = createFilters(this.search.result, this.search.districts, this.search.chains);

        if (0 === page) {
            this.booking.request = null;
            this.booking.result = {};
        }
    }

    updateSearchResultStatus({ resultCount, taskState }) {
        this.search.taskState = taskState;
        if (resultCount !== undefined) this.search.resultCount = resultCount;
    }

    get hotelArray() {
        return applyFilters(this.search.result, this.selected.filters) || [];
    }

    searchChecked() {
        this.search.lastCheckedAt = Number(new Date());
    }

    setSearchIsLoading(value) {
        this.search.loading = value;
    }

    setSearchSelectedFilters(filters) {
        this.selected.filters = filters;
    }

    setSearchSelectedSorter(sorter) {
        this.selected.sorter = sorter;
    }

    get hasAnyFilters() {
        return atLeastOne(this.selected.filters);
    }

    setSelectedAccommodationFullDetails(details) {
        this.selected.accommodationFullDetails = details;
    }

    selectSearchResultAccommodation(accommodation, roomContractSets) {
        if (undefined === roomContractSets) {
            this.search.roomsTaskState = SEARCH_STATUSES.CREATED;
            this.search.roomsCreatedAt = Number(new Date());
            this.search.roomsLastCheckedAt = null;
            this.selected.accommodationFullDetails = null;
        } else {
            roomContractSets.sort((a, b) => (a.groupName ? 0 : 1) - (b.groupName ? 0 : 1));
        }

        this.selected.accommodation = {
            ...(accommodation || {}),
            roomContractSets: (roomContractSets || []).map((contractSet, index) => ({
                ...contractSet,
                index,
            })),
        };
        this.search.roomsResultCount = roomContractSets?.length || 0;
    }

    selectRoomContractSet(result, preloaded) {
        if (
            result.roomContractSet?.rate.finalPrice.currency !== preloaded.rate?.finalPrice.currency ||
            result.roomContractSet?.rate.finalPrice.amount !== preloaded.rate?.finalPrice.amount
        )
            result.roomContractSet.priceChangedAlert = true;

        result.roomContractSet.rooms.forEach((room, index) => (room._index = index + 1));

        if (result.roomContractSet?.deadline.date !== preloaded.deadline?.date)
            result.roomContractSet.deadlineChangedAlert = true;

        result.roomContractSet.availablePaymentMethods = result.availablePaymentMethods;

        this.selected = {
            ...this.selected,
            accommodationFinal: result,
            roomContractSet: result.roomContractSet,
            availabilityId: result?.availabilityId,
        };
        this.booking = {
            request: {},
            result: null,
        };
    }

    setRoomsTaskState(taskState, resultCount) {
        this.search.roomsTaskState = taskState;
        this.search.roomsResultCount = resultCount;
        this.search.roomsLastCheckedAt = Number(new Date());
    }

    setBookingRequest(request) {
        this.booking.request = request;
    }

    setLoadingRooms(value) {
        this.search.isLoadingRooms = value;
    }

    setSearchDistricts(value) {
        this.search.districts = value;
    }

    setSearchChains(value) {
        this.search.chains = value;
    }

    setCustomMarkup(markup, price) {
        this.search.request.markup = markup;
        this.search.request.customPrice = price;
    }
}

export default new AccommodationStore();
