import React from 'react';
import { observer } from 'mobx-react';
import RoomSummary from '../parts/room-summary';
import { $accommodation } from 'stores';

const RoomContractSetGroup = observer(
    ({ expandedGroups, setExpandedGroups, group, groupsCount, roomContractSetSelect }) => {
        const selectedAccommodation = $accommodation.selected.accommodation;

        const isExpanded = expandedGroups?.includes(group?.index);

        const toggle = () => {
            setExpandedGroups((prevState) => {
                if (prevState.includes(group.index)) {
                    return prevState.filter((item) => item !== group.index);
                }
                return [...prevState, group.index];
            });
        };

        return (
            <div className="group">
                {groupsCount > 1 ? (
                    <div className="name" onClick={toggle}>
                        {group.groupName || 'Other Room Categories'} ({group.roomContractSets?.length || 0})
                        <span className={'icon icon-big-expand' + (isExpanded ? ' expanded' : '')} />
                    </div>
                ) : null}
                {isExpanded
                    ? group.roomContractSets?.map((roomContractSet, index) => (
                          <RoomSummary
                              key={index}
                              roomContractSet={roomContractSet}
                              onSelect={() => roomContractSetSelect(roomContractSet)}
                              htId={selectedAccommodation.htId}
                              accommodationName={selectedAccommodation.name}
                          />
                      ))
                    : null}
            </div>
        );
    }
);

export default RoomContractSetGroup;
