import React, { useState } from 'react';
import { CachedForm, FieldText } from 'components/form';
import { observer } from 'mobx-react';
import ModalTemplate from 'common/template/modal-template';
import { $accommodation, $notifications } from 'stores';
import { price } from '../../../simple';
import { calculateCustomPrice } from '../parts/booking-summary';

const calcPrice = (price, percent) => {
    return ((price * (100 + percent)) / 100).toFixed(2);
};

const EditMarkupModal = observer((props) => {
    const { booking } = props;

    if (!props.isOpen || !booking) return null;

    const basePrice = booking.rate.finalPrice.amount;
    const markup = booking.rate.totalMarkupPercent;
    const rawPrice = (basePrice * 100) / (100 + markup);

    const isMultiRoom = booking.rooms.length > 1;

    const initialValues = {
        price: basePrice,
        markup: markup,
    };

    if ($accommodation.search.request.markup) {
        initialValues.price = String(calcPrice(rawPrice, $accommodation.search.request.markup));
        initialValues.markup = String($accommodation.search.request.markup);
    }

    const submit = (values) => {
        if (!values.markup || values.markup == markup) {
            $accommodation.setCustomMarkup(null);
            props.onClose();
            return;
        }
        if (!values.price || values.price < basePrice || values.markup < markup) {
            $notifications.addNotification(
                `Markup should not be less than ${markup}% and Final price should not be less than ${basePrice}`
            );
            return;
        }
        $accommodation.setCustomMarkup(parseFloat(values.markup), parseFloat(values.price));
        props.onClose();
    };

    const onPriceChange = (event, formik) => {
        if (event.target.value?.split('.')?.[1]?.length > 2) {
            event.target.value = parseFloat(event.target.value).toFixed(2);
        }

        const value = parseFloat(event.target.value);

        if (value && value > basePrice) {
            formik.setFieldValue('markup', (((value - rawPrice) / rawPrice) * 100).toFixed(2));
        } else {
            formik.setFieldValue('markup', markup);
        }
    };

    const onMarkupChange = (event, formik) => {
        if (event.target.value?.split('.')?.[1]?.length > 2) {
            event.target.value = parseFloat(event.target.value).toFixed(2);
        }

        const value = parseFloat(event.target.value);

        if (value && value > markup) {
            formik.setFieldValue('price', calcPrice(rawPrice, value));
        } else {
            formik.setFieldValue('price', basePrice);
        }
    };

    return (
        <ModalTemplate addClass="wide" {...props}>
            <h2>Room &amp; Total Cost Settings</h2>

            <CachedForm
                initialValues={initialValues}
                onSubmit={submit}
                render={(formik) => (
                    <div className="form">
                        <div style={{ marginBottom: 20 }}>Please enter new total cost price or markup</div>
                        {isMultiRoom &&
                            booking.rooms.map((room, _index) => (
                                <div style={{ marginBottom: 20 }} key={_index}>
                                    <FieldText
                                        formik={formik}
                                        label={'Room ' + (_index + 1) + ' Cost'}
                                        Icon={<>{booking.rate.finalPrice.currency}</>}
                                        disabled
                                        value={calculateCustomPrice(
                                            room.rate?.finalPrice.amount,
                                            markup,
                                            formik.values.markup || markup
                                        )}
                                    />
                                </div>
                            ))}
                        <div style={{ marginBottom: 20 }}>
                            <FieldText
                                formik={formik}
                                id="price"
                                label="Total Cost"
                                placeholder="Enter New Total Cost"
                                numeric="."
                                Icon={<>{booking.rate.finalPrice.currency}</>}
                                onChange={(event) => onPriceChange(event, formik)}
                            />
                        </div>
                        <div>
                            <FieldText
                                formik={formik}
                                id="markup"
                                label="Markup"
                                placeholder="Enter Markup"
                                numeric="."
                                Icon={<>%</>}
                                onChange={(event) => onMarkupChange(event, formik)}
                            />
                        </div>
                        <div className="bottom" style={{ display: 'flex', gap: '25px' }}>
                            <button className="button" style={{ marginRight: 0 }} onClick={props.onClose}>
                                Cancel
                            </button>
                            <button
                                className={
                                    'button main' +
                                    __class(!formik.values.price || !formik.values.price, 'disabled')
                                }
                                disabled={!formik.values.price || !formik.values.price}
                                type="submit"
                                style={{ marginRight: 0 }}
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                )}
            />
        </ModalTemplate>
    );
});

export default EditMarkupModal;
