import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTitle } from 'htcore';
import { SEARCH_STATUSES } from 'enum';
import {
    searchSecondStepLoadWithNewFilters,
    searchSecondStepLoadWithNewOrder,
} from 'tasks/accommodation/search-rooms-loaders';
import ViewFailed from 'common/misc/view-failed';
import SearchFilters from 'pages/accommodation/search-filters/search-filters';
import { Loader } from 'components/simple';
import { HotelStars } from 'components/accommodation';
import Breadcrumbs from 'components/navigation/breadcrumbs';
import RoomContractSetsList from './room-contract-sets-list';
import HotelDetailsModal from './hotel-details-modal';
import CustomSearchNotification from '../custom-search-notification';
import { $accommodation } from 'stores';

const AccommodationRoomContractsSetsPage = observer(() => {
    useTitle('Select An Accommodation');
    const [filtersLastUpdated, setFiltersLastUpdated] = useState(0);
    const [isAccommodationDetailsModalOpen, setAccommodationDetailsModalOpened] = useState(false);
    const [expandedGroups, setExpandedGroups] = useState(null);

    const selectedAccommodation = $accommodation.selected.accommodation;
    const details = $accommodation.selected.accommodationFullDetails || selectedAccommodation?.accommodation;
    const { roomsTaskState } = $accommodation.search;

    useEffect(() => {
        if (!expandedGroups && selectedAccommodation.roomContractSets.length) {
            setExpandedGroups(selectedAccommodation.roomContractSets.map(({ index }) => index));
        }
    }, [selectedAccommodation.roomContractSets]);

    if (!details) return <Loader page />;

    return (
        <div className="search-results block with-padding">
            <CustomSearchNotification />
            <section>
                <Breadcrumbs backLink="/search/results" backText="View Other Options" />
            </section>
            <section>
                <div
                    className={'details-short' + __class(details?.isPreferred, 'preferred')}
                    onClick={() => setAccommodationDetailsModalOpened(true)}
                >
                    <div className="head">
                        {Boolean(details?.photos?.length) && (
                            <div className="photo">
                                {details?.isPreferred && <div className="plate">Preferred</div>}
                                <img src={details.photos[0].sourceUrl} alt={details.photos[0].caption} />
                            </div>
                        )}
                        <div className="title">
                            <HotelStars count={details.rating} />
                            {Boolean(details?.isPreferred && !details?.photos?.length) && (
                                <div className="plate no-photo">Preferred</div>
                            )}
                            <h1>{details.name}</h1>
                        </div>
                        <div className="short-details">
                            <div>
                                {details.location.locality}, {details.location.address}
                            </div>
                            {details.contacts?.phones?.length &&
                                details.contacts.phones[0] &&
                                details.contacts.phones[0].length > 6 && (
                                    <div>
                                        {details.contacts.phones[0][0] === '+'
                                            ? details.contacts.phones[0]
                                            : `+${details.contacts.phones[0]}`}
                                    </div>
                                )}

                            {details.schedule.checkInTime && (
                                <div>
                                    {[
                                        `Check-in from ${
                                            details.schedule.checkInTime?.substr(0, 5) ||
                                            details.schedule.checkInTime
                                        }`,
                                        details.schedule.checkOutTime
                                            ? `Check-out till ${
                                                  details.schedule.checkOutTime?.substr(0, 5) ||
                                                  details.schedule.checkOutTime
                                              }`
                                            : null,
                                    ]
                                        .filter((v) => v)
                                        .join(' • ')}
                                </div>
                            )}
                        </div>
                    </div>
                    <button className="button main small">Show Details</button>
                </div>
            </section>
            <section>
                <SearchFilters
                    key={filtersLastUpdated}
                    update={setFiltersLastUpdated}
                    filterAction={searchSecondStepLoadWithNewFilters}
                    sorterAction={searchSecondStepLoadWithNewOrder}
                    showExpand={selectedAccommodation?.roomContractSets?.length > 1}
                    expandedGroups={expandedGroups}
                    setExpandedGroups={setExpandedGroups}
                    secondStep
                />

                {SEARCH_STATUSES.BROKEN === roomsTaskState ? (
                    <ViewFailed button="View Other Options" link="/search/results" />
                ) : (
                    <RoomContractSetsList
                        expandedGroups={expandedGroups}
                        setExpandedGroups={setExpandedGroups}
                        secondStep
                        setFiltersLastUpdated={setFiltersLastUpdated}
                    />
                )}
            </section>
            <HotelDetailsModal
                isOpen={isAccommodationDetailsModalOpen}
                onClose={() => setAccommodationDetailsModalOpened(false)}
                addClass="accommodation-details-modal"
                blur={false}
            />
        </div>
    );
});

export default AccommodationRoomContractsSetsPage;
