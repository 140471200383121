import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { date, price, useDropdown } from 'simple';
import AccommodationConfirmationVoucherButtons from '../confirmation/voucher-buttons';
import AccommodationConfirmationInvoiceButtons from '../confirmation/invoice-buttons';
import { $auth } from 'stores';

const BookingActionPart = observer(
    ({ isService, booking, showCancellationConfirmation, payNowByCard, isRoomCancellationAvailable }) => {
        const details = booking.bookingDetails;
        const refElement = useRef(null);
        const refDropdown = useRef(null);
        const [dropdownOpen, toggleDropdown] = useDropdown(refElement, refDropdown);

        let voucherElements = null;
        let invoiceElements = null;

        if (
            !isService &&
            $auth.permitted('BookingDocuments') &&
            ('VirtualAccount' === booking.paymentMethod ||
                'Success' === booking.paymentStatus ||
                'Invoiced' === booking.paymentStatus ||
                'Authorized' === booking.paymentStatus) &&
            ('Confirmed' === details.status || 'Completed' === details.status)
        ) {
            voucherElements = (
                <AccommodationConfirmationVoucherButtons
                    isItineraryBooking={booking.itineraryBookingsCount > 1}
                    bookingId={booking.bookingId}
                    referenceCode={booking.bookingDetails.referenceCode}
                />
            );
        }

        if (
            $auth.permitted('BookingDocuments') &&
            (('Cancelled' !== details.status &&
                'PendingCancellation' !== details.status &&
                'InternalProcessing' !== details.status &&
                'ManualCorrectionNeeded' !== details.status &&
                'Rejected' !== details.status &&
                'Discarded' !== details.status) ||
                booking.appliedCancellationFee?.amount)
        ) {
            invoiceElements = (
                <AccommodationConfirmationInvoiceButtons
                    isService={isService}
                    isItineraryBooking={booking.itineraryBookingsCount > 1}
                    bookingId={booking.bookingId}
                    referenceCode={booking.bookingDetails.referenceCode}
                />
            );
        }

        return (
            <div className="actions">
                {Boolean(voucherElements || invoiceElements) && (
                    <div className="item" style={{ position: 'relative' }}>
                        <div className="button" onClick={toggleDropdown} ref={refElement}>
                            {[voucherElements ? 'Voucher' : '', invoiceElements ? 'Invoice' : '']
                                .filter((item) => item)
                                .join(' & ')}
                        </div>
                        <div className={'select dropdown' + __class(!dropdownOpen, 'hide')}>
                            <div className="scroll">
                                {voucherElements}
                                {invoiceElements}
                            </div>
                        </div>
                    </div>
                )}
                {$auth.permitted('ModifyBooking') &&
                    'NotPaid' === booking.paymentStatus &&
                    'Cancelled' !== details.status &&
                    'PendingCancellation' !== details.status &&
                    'Invalid' !== details.status &&
                    'Rejected' !== details.status &&
                    'Discarded' !== details.status &&
                    Boolean(booking.creditCardPrice) &&
                    !booking.surrogateAgencyName && (
                        <button className="button" onClick={payNowByCard}>
                            Pay Now by Card for {price(booking.creditCardPrice)}
                        </button>
                    )}
                {!booking?.isOffline &&
                    !isService &&
                    $auth.permitted('ModifyBooking') &&
                    'Cancelled' !== details.status &&
                    'PendingCancellation' !== details.status &&
                    'ManualCorrectionNeeded' !== details.status &&
                    'Invalid' !== details.status &&
                    'Rejected' !== details.status &&
                    'Discarded' !== details.status &&
                    !date.isPast(details.checkOutDate) && (
                        <button className="button" onClick={showCancellationConfirmation}>
                            {isRoomCancellationAvailable && 'Modify & '} Cancel Booking
                        </button>
                    )}

                {(booking?.isOffline || isService) && details.status === 'Confirmed' && (
                    <div className="offline-booking-cancel">
                        Please Contact HappyTravel.com team to <br /> Cancel this booking
                    </div>
                )}
            </div>
        );
    }
);

export default BookingActionPart;
