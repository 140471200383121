import { date } from 'simple';
import { $auth } from 'stores';

export const searchFormValuesCorrection = (values) => {
    if (!values.residency || !values.residencyCode) {
        values.residency = $auth.settings.residency || '';
        values.residencyCode = $auth.settings.residencyCode || '';
    }
    if (!values.nationality || !values.nationalityCode) {
        values.nationality = $auth.settings.nationality || '';
        values.nationalityCode = $auth.settings.nationalityCode || '';
    }
    if (
        date.isPast(values.checkInDate) ||
        date.isPast(values.checkOutDate) ||
        new Date(values.checkInDate) > new Date(values.checkOutDate)
    ) {
        let targetDay = new Date();
        if (!$auth.agencySettings.acceptLastMinuteBookings) {
            let Saturday = new Date();
            Saturday.setDate(Saturday.getDate() + ((4 + 7 - Saturday.getDay()) % 7) || 7);
            targetDay = Saturday;
        }
        values.checkInDate = targetDay;
        values.checkOutDate = date.addDay(targetDay, 1);
    }
    return values;
};

export const searchFormFormatter = (values) => {
    let roomDetails = [];
    for (let i = 0; i < values.roomDetails.length; i++) {
        let room = {
            adultsNumber: values.roomDetails[i].adultsNumber,
            childrenNumber: values.roomDetails[i].childrenAges.length,
        };
        if (values.roomDetails[i].childrenAges.length) {
            room.childrenAges = [];
            for (let j = 0; j < values.roomDetails[i].childrenAges.length; j++)
                room.childrenAges.push(parseInt(values.roomDetails[i].childrenAges[j] || 9));
        }
        roomDetails.push(room);
    }

    const surrogateSearchDetails = {};
    if (values.isSurrogateSearch) {
        surrogateSearchDetails.surrogateAgencyId = values.surrogateAgencyId;
        surrogateSearchDetails.surrogateAgencyName = values.surrogateAgencyIdCode;
        surrogateSearchDetails.currency = values.currency;
    }

    return {
        checkInDate: date.format.apiDateOnly(values.checkInDate),
        checkOutDate: date.format.apiDateOnly(values.checkOutDate),
        roomDetails: roomDetails,
        nationality: values.nationalityCode,
        residency: values.residencyCode,
        htIds: [values.destination],
        ...surrogateSearchDetails,
    };
};
