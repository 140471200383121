import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Formik } from 'formik';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { price } from 'simple';
import { FieldSelect } from 'components/form';
import { $accommodation, $auth, $ui } from 'stores';

export const AccountSelectorField = ({ formik, disabled, setValue, currencies, accounts }) => {
    return (
        <FieldSelect
            formik={formik}
            id="currency"
            label="Active Currency"
            placeholder="Select Account"
            setValue={setValue}
            disabled={disabled}
            caption={
                <div
                    style={{
                        fontSize: '11.5px',
                        padding: '9px 10px 9px 27px',
                        cursor: 'default',
                    }}
                >
                    All searches and payments are always processed in the pre-chosen currency. You can change
                    the selected currency only before the search
                </div>
            }
            options={[
                ...(accounts?.map((account) => ({
                    value: account.currency,
                    text: (
                        <>
                            {account.balance ? price(account.balance) : account.currency}
                            <span
                                style={{
                                    fontWeight: 400,
                                    color: '#666',
                                    marginLeft: 8,
                                    fontSize: '10px',
                                }}
                            >
                                ({account.currency} Account)
                            </span>
                        </>
                    ),
                })) || []),
                ...(currencies
                    ?.filter((item) => !accounts || !accounts.some((account) => account.currency === item))
                    .map((currency) => ({
                        value: currency,
                        text: (
                            <>
                                {currency}
                                {Boolean(accounts) && (
                                    <span
                                        style={{
                                            fontWeight: 400,
                                            color: '#666',
                                            marginLeft: 8,
                                            fontSize: '10px',
                                        }}
                                    >
                                        (No Account)
                                    </span>
                                )}
                            </>
                        ),
                    })) || []),
            ]}
        />
    );
};

const AccountSelector = observer(({ accounts, setAccounts }) => {
    const [currencies, setCurrencies] = useState(null);
    const [preferredCurrency, setPreferredCurrency] = useState(null);

    const isItnMode = Boolean($ui.itnMode || $accommodation.search?.request?.surrogateAgencyId);
    const onDismiss = () => {
        $ui.resetItnMode();
        $accommodation.clearSearch();
    };

    useEffect(() => {
        if ($auth.agency?.contractKind === 'VirtualAccountOrCreditCardPayments') {
            API.get({
                url: apiMethods.AGENCY_ACCOUNTS,
                success: setAccounts,
            });
        }
        if (!currencies) {
            API.get({
                url: apiMethods.AGENCY_CURRENCIES,
                success: setCurrencies,
            });
        }
        if (!preferredCurrency) {
            API.get({
                url: apiMethods.PREFERRED_CURRENCY,
                success: setPreferredCurrency,
            });
        }
    }, [$auth.agency?.contractKind]);

    const onSelect = (currency) => {
        API.put({
            url: apiMethods.PREFERRED_CURRENCY,
            body: {
                preferredCurrency: currency,
            },
        });
        setPreferredCurrency(currency);
    };

    if ((!accounts?.length && !currencies?.length) || !preferredCurrency) {
        return null;
    }

    if (accounts?.length === 1 || currencies?.length === 1) {
        if (!accounts?.length) {
            return null;
        }
        let onlyAccount;
        if (currencies?.length === 1) {
            onlyAccount = accounts.find((account) => account.currency === currencies[0]);
        } else {
            onlyAccount = accounts[0];
        }
        if (!onlyAccount) {
            return null;
        }

        return (
            <>
                Balance:
                <span className={onlyAccount.balance.amount > 0 ? 'positive' : ''}>
                    {price(onlyAccount.balance)}
                </span>
            </>
        );
    }

    if (currencies?.length === 1) {
        return null;
    }

    return (
        <div className="form">
            <Formik
                initialValues={{
                    currency: preferredCurrency,
                }}
                onSubmit={() => null}
            >
                {(formik) => (
                    <form>
                        {isItnMode && (
                            <div style={{ marginTop: 30 }}>
                                <h3>
                                    <strong>Itinerary Mode</strong>
                                </h3>
                                <div style={{ padding: '10px 0' }}>
                                    You are currently adding a new booking to the itinerary.
                                    <br /> It makes impossible to select the another currency.
                                </div>
                            </div>
                        )}
                        <div
                            className="row"
                            style={{ minWidth: 280, maxWidth: 340, position: 'relative', zIndex: 900 }}
                        >
                            <AccountSelectorField
                                formik={formik}
                                disabled={isItnMode}
                                setValue={onSelect}
                                accounts={accounts}
                                currencies={currencies}
                            />
                        </div>
                        {isItnMode && (
                            <div
                                className="button main small"
                                style={{ width: 'auto', marginBottom: 30 }}
                                onClick={onDismiss}
                            >
                                Dismiss Itinerary Mode
                            </div>
                        )}
                    </form>
                )}
            </Formik>
        </div>
    );
});

export default AccountSelector;
