import React from 'react';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { useNavigate } from 'react-router-dom';
import { $ui } from 'stores';
import { FORM_NAMES } from '../../../components/form';

const BookingItinerary = ({
    itineraryNumber,
    isContinuePossible,
    bookingCurrency,
    surrogateAgencyName,
    surrogateAgencyId,
}) => {
    const navigate = useNavigate();

    const addBooking = () => {
        API.put({
            url: apiMethods.PREFERRED_CURRENCY,
            body: {
                preferredCurrency: bookingCurrency,
            },
        });
        $ui.setIntMode(itineraryNumber, {
            surrogateAgencyName,
            surrogateAgencyId,
            currency: bookingCurrency,
        });
        let values = JSON.parse($ui.formCache[FORM_NAMES.SearchForm]);
        if (surrogateAgencyName) {
            values = {
                ...values,
                isSurrogateSearch: true,
                surrogateAgencyId,
                surrogateAgencyIdCode: surrogateAgencyName,
                currency: bookingCurrency,
            };
        } else {
            values = {
                ...values,
                isSurrogateSearch: false,
                surrogateAgencyId: null,
                surrogateAgencyIdCode: null,
                currency: null,
            };
        }
        $ui.formCache[FORM_NAMES.SearchForm] = JSON.stringify(values);
        navigate('/search');
    };

    return (
        <div className="itinerary-number">
            <p>
                <span>Itinerary Number:</span> <strong>{itineraryNumber}</strong>
            </p>
            {isContinuePossible && (
                <button className="button main" onClick={addBooking}>
                    Add New Booking to Itinerary
                    {surrogateAgencyName ? (
                        <>
                            <br />
                            {` for ${surrogateAgencyName}`}
                        </>
                    ) : null}
                </button>
            )}
        </div>
    );
};

export default BookingItinerary;
