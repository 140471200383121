import React, { useState } from 'react';
import FieldText from '../field-text';
import FieldSelectDropdown from './field-select-dropdown';

const getCodeFieldId = (field) => field + 'Code';

const FieldSelectWithSearch = (props) => {
    const { formik, id, options, className, value, setValue } = props;
    const [suggestion, setSuggestion] = useState(null);
    const [filteredOptions, setFilteredOptions] = useState(options);

    const makeList = (value) => {
        let newOptions = options;
        if (value) {
            newOptions = options.filter(
                (item) => item.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
            );
            if (newOptions?.length) {
                setSuggestion({ text: newOptions[0].text, value: newOptions[0] });
            } else {
                setSuggestion(null);
            }
        }
        setFilteredOptions(newOptions);
    };

    const select = (item, silent) => {
        if (silent) return;
        if (setValue) setValue(item.value);
        formik.setFieldValue(id, item.value);
        formik.setFieldValue(getCodeFieldId(id), item.text);
        setSuggestion(null);
        makeList(null);
    };

    const inputChanged = (event) => {
        makeList(event.target.value);
        formik.setFieldValue(id, null);
    };

    const onFocusChanged = (focused) => {
        setSuggestion((currentSuggestion) => {
            if (!focused && !formik.values[id]) {
                if (currentSuggestion) {
                    select(currentSuggestion.value);
                }
                return null;
            }
        });

        if (!focused && !formik.values[id] && formik.values[getCodeFieldId(id)] && !suggestion) {
            formik.setFieldValue(getCodeFieldId(id), '');
        }
        if (!formik.values[id] && !suggestion) {
            makeList(null);
        }
    };

    return (
        <FieldText
            {...props}
            id={getCodeFieldId(id)}
            AfterIcon={<span className="icon icon-arrow-expand" />}
            className={'select-with-search' + __class(className)}
            Dropdown={FieldSelectDropdown}
            options={filteredOptions}
            setValue={select}
            onChange={inputChanged}
            suggestion={suggestion}
            additionalFieldForValidation={getCodeFieldId(id)}
            onFocusChanged={onFocusChanged}
            disabled={props.disabled}
            caption={
                !filteredOptions?.length && (
                    <div
                        style={{
                            fontSize: '11.5px',
                            padding: '9px 10px 9px 27px',
                            cursor: 'default',
                        }}
                    >
                        No matches
                    </div>
                )
            }
        />
    );
};

export default FieldSelectWithSearch;
