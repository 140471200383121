import React from 'react';
import { observer } from 'mobx-react';
import { $accommodation } from 'stores';

const AccommodationExpandRoomGroups = observer(({ expandedGroups, setExpandedGroups }) => {
    const { selected } = $accommodation;
    const roomContractSets = selected.accommodation.roomContractSets;

    const isOpenAll = expandedGroups?.length === roomContractSets.length;

    const expandAll = () => {
        setExpandedGroups(() => (isOpenAll ? [] : roomContractSets.map(({ index }) => index)));
    };

    return (
        <div className="item">
            <div className={'button dark-button' + __class(true, 'after-icon')} onClick={expandAll}>
                {isOpenAll ? 'Close All Room Types' : 'Open All Room Types'}
                <span
                    className={'icon' + __class(isOpenAll, 'icon-small-expand expanded', 'icon-small-expand')}
                />
            </div>
        </div>
    );
});

export default AccommodationExpandRoomGroups;
