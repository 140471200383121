import React, { useState } from 'react';
import { observer } from 'mobx-react';
import EditMarkupModal from '../confirmation/edit-markup-modal';
import { $accommodation } from 'stores';

const BookingMarkupEditionButton = observer(({ isService, booking }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    if (isService || !$accommodation.search?.request?.surrogateAgencyId) {
        return null;
    }

    return (
        <div>
            <EditMarkupModal booking={booking} isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
            <span
                className="button main small"
                onClick={() => setModalOpen(true)}
                style={{ marginTop: 0, fontSize: '14px' }}
            >
                Edit
            </span>
        </div>
    );
});

export default BookingMarkupEditionButton;
