import React from 'react';
import {
    date,
    price,
    PassengerName,
    remapStatus,
    remapBoardBasis,
    remapStatusExtendedWithRooms,
} from 'simple';
import { downloadVoucher } from '../accommodation/confirmation/voucher-buttons';
import { $auth } from 'stores';

const AgentCell = (row, setAgentIdFilter) => (
    <span
        className="link"
        onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setAgentIdFilter(row.agent.id);
        }}
    >
        {row.agent?.firstName} {row.agent?.lastName}
    </span>
);

const LeadingPassengersCell = (row) =>
    PassengerName({ passenger: row.rooms?.[0]?.passengers?.[0] }) ||
    PassengerName({ passenger: row.rooms?.[0] }) ||
    'None';

const VoucherCell = (booking, allVouchers) =>
    booking.isServiceCell ? null : (
        <>
            <strong style={{ marginTop: -13 }}>Voucher</strong>
            <button style={{ margin: -10, padding: 10 }}>
                <i
                    className="icon icon-download"
                    onClick={(e) => {
                        e.stopPropagation();
                        downloadVoucher(booking.id, booking.referenceCode, allVouchers);
                    }}
                />
            </button>
        </>
    );

export const Columns = (
    permittedAgency,
    areBookingDocumentsAvailable,
    setAgentIdFilter,
    innerTable,
    isService
) => [
    ...(permittedAgency && !innerTable
        ? [
              {
                  header: 'Agent',
                  cell: (row) => AgentCell(row, setAgentIdFilter),
              },
          ]
        : []),
    {
        header: 'Reference Codes',
        cell: (row) => (
            <>
                {!innerTable && (
                    <>
                        <small style={{ color: '#666' }}>{row.itineraryNumber}</small>
                        <br />
                    </>
                )}
                {row.surrogateAgencyName && (
                    <small
                        style={{
                            color: '#1890FF',
                            background: '#D1E9FF',
                            fontSize: '14px',
                            lineHeight: '22px',
                            borderRadius: '4px',
                            padding: '0 5px',
                        }}
                    >
                        SA
                    </small>
                )}{' '}
                {row.referenceCode}
                <br />
                {row.supplierReferenceCode && row.supplierReferenceCode !== row.referenceCode && (
                    <small style={{ color: '#666' }}>{row.supplierReferenceCode}</small>
                )}
            </>
        ),
    },
    {
        header: 'Accommodation / Location',
        cell: (row) => (
            <>
                {row.accommodationName}

                {row.countryName && row.localityName && (
                    <div>
                        {row.countryName}, {row.localityName}
                    </div>
                )}
            </>
        ),
    },

    ...(isService || innerTable
        ? [
              {
                  header: 'Service Type / Name',
                  cell: (row) =>
                      row.serviceType || row.serviceName ? (
                          <>
                              {row.serviceType}
                              <br />
                              {row.serviceName}
                          </>
                      ) : (
                          'Accommodation'
                      ),
              },
          ]
        : []),
    {
        header: 'Leading Passenger',
        cell: LeadingPassengersCell,
    },
    {
        header: 'Dates',
        cell: (row) =>
            new Date(row.checkOutDate) <= 0 ? (
                'None'
            ) : (
                <>
                    {date.format.c(date.day(row.checkInDate))}&nbsp;—
                    <br /> {date.format.c(date.day(row.checkOutDate))}
                </>
            ),
    },
    {
        header: 'Payment',
        cell: (row) => (
            <>
                <span className={'status ' + row.paymentStatus}>{remapStatus(row.paymentStatus)}</span>
                <br />
                <span className="payment-amount">
                    {'Cancelled' === row.status ? price(row.appliedCancellationFee) : price(row.price)}
                </span>
            </>
        ),
    },
    {
        header: 'Deadline',
        cell: (row) => (
            <>{row.deadline ? date.format.deadlineShort(row.deadline, row.checkInDate) : 'None'}</>
        ),
    },
    {
        header: 'Status',
        cell: (row) => (
            <span
                className={'status ' + remapStatusExtendedWithRooms(row.status, row.rooms).replace(/\s/g, '')}
            >
                {remapStatusExtendedWithRooms(row.status, row.rooms)}
            </span>
        ),
    },
    ...($auth.permitted('BookingDocuments') && areBookingDocumentsAvailable
        ? [
              {
                  header: null,
                  cell: (booking) =>
                      // todo: rewrite this part, create a common code to detect whenever booking documents are available
                      ($auth.agency?.contractKind === 'VirtualAccountOrCreditCardPayments' ||
                          'Success' === booking.paymentStatus ||
                          'Invoiced' === booking.paymentStatus ||
                          'Authorized' === booking.paymentStatus) &&
                      ('Confirmed' === booking.status || 'Completed' === booking.status)
                          ? VoucherCell(booking)
                          : null,
              },
          ]
        : []),
];

export const Sorters = (permittedAgency) => [
    {
        title: 'Creation Date',
        sorter: (v) => v.id,
    },
    {
        title: 'Creation (old first)',
        sorter: (v) => -v.id,
    },
    ...(permittedAgency
        ? [
              {
                  title: 'Agent',
                  sorter: (v) => v.agent?.lastName,
              },
          ]
        : []),
    {
        title: 'Deadline',
        sorter: (v) => {
            if (!v.deadline || new Date(v.deadline) < 10) {
                return -Infinity;
            }
            if (v.status === 'Completed') {
                return Number(new Date(v.deadline));
            }
            if (date.addDay(new Date(v.deadline), 2) > new Date()) {
                return Number(10000 * new Date() - new Date(v.deadline));
            }
            return Number(new Date()) + Number(new Date(v.deadline));
        },
    },
    {
        title: 'Check-in Date',
        sorter: (v) => new Date(v.checkInDate),
    },
    {
        title: 'Status',
        sorter: (v) => v.status,
    },
    {
        title: 'Amount',
        sorter: (v) => v.price.amount,
    },
];

export const GroupSorters = () => [
    {
        title: 'Creation Date',
        sorter: (v) => v.children[0].id,
    },
    {
        title: 'Creation (old first)',
        sorter: (v) => -v.children[0].id,
    },
    {
        title: 'Agent',
        sorter: (v) => v.agent?.lastName,
    },
    {
        title: 'Check-in Date',
        sorter: (v) => new Date(v.dates[0].checkInDate),
    },
    {
        title: 'Amount',
        sorter: (v) => v?.total,
    },
];

export const Searches = (permittedAgency) => (v) =>
    [
        v.referenceCode,
        v.accommodationName,
        v.countryName,
        v.localityName,
        remapBoardBasis(v.boardBasis),
        remapStatusExtendedWithRooms(v.status, v.rooms),
        v.mealPlan,
        v.contractType,
        PassengerName({ passenger: v.rooms?.[0]?.passengers?.[0] }),
        remapStatus(v.paymentStatus),
        ...(permittedAgency && v.agent ? [v.agent?.firstName, v.agent?.lastName] : []),
        v.supplierReferenceCode,
        v.itineraryNumber,
        ...(v.children || []).map((child) => Searches(permittedAgency)(child)).flat(),
    ];

export const ParentRowColumns = (setAgentIdFilter) => [
    {
        header: 'Agent',
        cell: (row) => AgentCell(row, setAgentIdFilter),
    },
    {
        header: 'Itinerary Number',
        cell: 'itineraryNumber',
    },
    {
        header: 'Leading Passenger',
        cell: LeadingPassengersCell,
    },
    {
        header: 'Total Bookings',
        cell: 'itineraryBookingsCount',
    },
    {
        header: 'Total Amounts',
        cell: (row) => (row.total ? price(row.currency, row.total) : ''),
    },
    {
        header: 'Dates',
        cell: (row) =>
            row.dates.slice(0, 2).map(({ checkInDate, checkOutDate }, index) =>
                new Date(checkInDate) <= 0 ? (
                    'None'
                ) : (
                    <div key={index}>
                        {date.format.c(date.day(checkInDate))}—{date.format.c(date.day(checkOutDate))}
                        <br />
                    </div>
                )
            ),
    },
    ...($auth.permitted('BookingDocuments')
        ? [
              {
                  header: null,
                  cell: (booking) => VoucherCell(booking, true),
              },
          ]
        : []),
];
