import * as Yup from 'yup';

export default Yup.object().shape({
    destination: Yup.string().required('*'),
    destinationInput: Yup.string().required('*'),
    residency: Yup.string().required('*'),
    nationality: Yup.string().required('*'),
    nationalityCode: Yup.string().required('*'),
    residencyCode: Yup.string().required('*'),
    surrogateAgencyId: Yup.string()
        .nullable()
        .when('isSurrogateSearch', {
            is: true,
            then: Yup.string().required('*'),
        }),
    currency: Yup.string()
        .nullable()
        .when('isSurrogateSearch', {
            is: true,
            then: Yup.string().required('*'),
        }),
});
