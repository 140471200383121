import React, { useMemo } from 'react';
import { useTitle } from 'htcore';
import { windowSessionStorage } from 'htcore/misc/storage';
import Breadcrumbs from 'components/navigation/breadcrumbs';
import BookingConfirmationView from './parts/booking-confirmation-view';
import { useParams } from 'react-router-dom';
import { BOOKING_LINK } from '../bookings-management/bookings-management';

const AccommodationViewBookingPage = () => {
    useTitle('Booking');
    const { code } = useParams();

    const serviceCode = code.includes('MPS') ? code : null;

    const backLink = useMemo(() => {
        const savedLink = windowSessionStorage.get(BOOKING_LINK);
        windowSessionStorage.set(BOOKING_LINK, '');

        return savedLink ? `/bookings/${savedLink}` : '/bookings';
    }, []);

    return (
        <div className="booking block">
            <section>
                <Breadcrumbs backLink={backLink} backText="Back to Bookings List" />

                <BookingConfirmationView referenceCode={code} serviceCode={serviceCode} />
            </section>
        </div>
    );
};

export default AccommodationViewBookingPage;
