import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { FieldSelect } from 'components/form';
import { API } from 'htcore';
import apiMethods from 'common/api-methods';
import { PAYMENT_METHODS } from 'enum';
import { price } from 'simple';
import { $accommodation, $payment, $auth } from 'stores';

const BookingPaymentMethodSelector = observer(({ contractPaymentMethods }) => {
    const [balance, setBalance] = useState(null);
    const { settings } = $auth;
    const { paymentMethod } = $payment;

    const [agenciesList, setAgenciesList] = useState([]);

    const surrogateAgencyAccount = useMemo(() => {
        if ($accommodation.search.request.surrogateAgencyId) {
            const agency = agenciesList.find(
                ({ id }) => id === $accommodation.search.request.surrogateAgencyId
            );
            if (agency) {
                const account = agency.accounts.find(
                    ({ currency }) => currency === $accommodation.search.request.currency
                );
                if (account) {
                    return account.balance;
                }
            }
        }
        return null;
    }, [agenciesList]);

    useEffect(() => {
        if ($accommodation.search.request.surrogateAgencyId) {
            API.get({
                url: apiMethods.SURROGATE_AGENCIES,
                success: (list) => setAgenciesList(list),
            });
        } else if (
            $auth.permitted('ObserveBalance') &&
            $auth.agency?.contractKind === 'VirtualAccountOrCreditCardPayments'
        ) {
            API.get({
                url: apiMethods.AGENCY_ACCOUNTS,
                success: (accounts) =>
                    setBalance(
                        accounts.find(
                            (account) =>
                                account.currency === $accommodation.selected.roomContractSet.rate.currency
                        )?.balance
                    ),
            });
        }

        let selectedMethod = PAYMENT_METHODS.ACCOUNT;
        if (!contractPaymentMethods.includes(selectedMethod)) selectedMethod = PAYMENT_METHODS.OFFLINE;
        if (!contractPaymentMethods.includes(selectedMethod)) selectedMethod = PAYMENT_METHODS.CARD;
        $payment.setPaymentMethod(selectedMethod);
    }, [$auth.agency?.contractKind, $accommodation.search.request.surrogateAgencyId]);

    const selectPaymentMethod = (method) => {
        $payment.setPaymentMethod(method);
    };

    let options = [];

    if (contractPaymentMethods.includes(PAYMENT_METHODS.ACCOUNT))
        options.push({
            value: PAYMENT_METHODS.ACCOUNT,
            text: (
                <>
                    Account balance{' '}
                    {settings.availableCredit === true && balance && (
                        <span className={balance?.balance > 0 ? 'balance' : null}>
                            ({price(balance).trim()})
                        </span>
                    )}
                </>
            ),
        });

    if (contractPaymentMethods.includes(PAYMENT_METHODS.CARD))
        options.push({
            value: PAYMENT_METHODS.CARD,
            text: (
                <>
                    Credit or Debit Card
                    <img src="/images/other/mc.png" alt="Mastercard" />
                    <img src="/images/other/visa.png" alt="Visa" />
                    <img src="/images/other/amex.png" alt="American Express" />
                </>
            ),
        });

    if (contractPaymentMethods.includes(PAYMENT_METHODS.OFFLINE))
        options.push({
            value: PAYMENT_METHODS.OFFLINE,
            text: (
                <>
                    Pay Later <span>(Offline)</span>
                </>
            ),
        });

    if ($accommodation.search.request.surrogateAgencyId) {
        options = [
            {
                value: PAYMENT_METHODS.ACCOUNT,
                text: (
                    <>
                        Account balance{' '}
                        <span className={surrogateAgencyAccount?.balance > 0 ? 'balance' : null}>
                            ({price(surrogateAgencyAccount).trim()})
                        </span>
                    </>
                ),
            },
        ];
    }

    return (
        <>
            <h4>Select Payment Method</h4>
            {$accommodation.search.request.surrogateAgencyId ? (
                <p
                    className="accent-frame"
                    style={{ margin: '-10px 0 15px', padding: '20px 27px', color: '#666' }}
                >
                    This payment will be charged from the surrogate agency{' '}
                    {$accommodation.search.request.surrogateAgencyName} account
                </p>
            ) : (
                <>
                    {!contractPaymentMethods.includes(PAYMENT_METHODS.CARD) &&
                        !['USD', 'AED', 'EUR', 'SAR', 'GBP', 'QAR'].includes(
                            $accommodation.selected.roomContractSet.rate.currency
                        ) && (
                            <p
                                className="accent-frame"
                                style={{ margin: '-10px 0 15px', padding: '20px 27px', color: '#666' }}
                            >
                                To pay this booking using credit card, please start a search in any other
                                currency
                            </p>
                        )}
                    {contractPaymentMethods.length > 1 && $payment.paymentMethod === PAYMENT_METHODS.CARD && (
                        <p
                            className="accent-frame"
                            style={{ margin: '-10px 0 15px', padding: '20px 27px', color: '#666' }}
                        >
                            Please note that the rates may change with a change in the payment option
                        </p>
                    )}
                </>
            )}
            <div className="form">
                <FieldSelect
                    className="methods-of-payment"
                    placeholder="Select Payment Method"
                    value={options.find((item) => item.value === paymentMethod)?.text}
                    options={options}
                    setValue={selectPaymentMethod}
                />
            </div>
        </>
    );
});

export default BookingPaymentMethodSelector;
