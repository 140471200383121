import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { $accommodation, $ui } from 'stores';

const SurrogateAgencyNotification = observer(() => {
    const navigate = useNavigate();

    const onDismiss = () => {
        $accommodation.clearSearch();
        $ui.resetItnMode();
        navigate('/search');
    };

    if ($ui.itnMode || !$accommodation.search.request.surrogateAgencyId) {
        return null;
    }

    return (
        <div className="custom-search-notification">
            <section>
                <div>
                    <span className="icon-information icon" />
                    <div>
                        <span className="hide-mobile">
                            <strong>Notification: </strong>
                            This booking is for the Surrogate Agency{' '}
                            <b>{$accommodation.search.request.surrogateAgencyName}</b> in{' '}
                            <b>{$accommodation.search.request.currency}</b> currency
                        </span>
                    </div>
                </div>
                <button className="button" onClick={onDismiss}>
                    Dismiss
                </button>
            </section>
        </div>
    );
});

export default SurrogateAgencyNotification;
