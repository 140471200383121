import React from 'react';
import ItineraryNotification from './custom-search/itinerary-notification';
import SurrogateAgencyNotification from './custom-search/surrogate-agency-notification';

const CustomSearchNotification = () => (
    <>
        <SurrogateAgencyNotification />
        <ItineraryNotification />
    </>
);

export default CustomSearchNotification;
